.CreatePlanPage, .PlanViewPage, .PlanPrintPage {
    
    .plan-title-input {
        font-size: 52px;
        font-style: normal;
        font-weight: 700;
        color:#004259;
        background:transparent;
        border:none !important;
        outline: none;
        margin:0 0 10px;
        padding:0px !important;
        height:auto !important;
    }
    
    .plan-title-input::placeholder {
        color: #B7B7B7;
    }

    #plan-description-edit {
        margin-bottom:30px;
    }

    .plan-add-modal {
        @extend .module-container;
        margin-top:20px;
        width:90%;
        padding:12px;

        input {
            background:#F7F7F7;
        }

        .btn-close-modal {
            position: absolute;
            top: -2px;
            right: 0px;
            cursor: pointer;
            font-size:18px;
            color:$brand-dark;
        }
    }

    .module-container .btn-need-item {
        position:relative;
        cursor: pointer;
        display: flex;
        padding: 12px;
        padding-right:15px;
        align-items: center;
        gap: 5px;
        align-self: stretch;
        border-radius: 10px;
        background: var(--Background-color-primary, #F7F7F7);
        color:$brand-dark;
        text-decoration:none;
        transition: background 0.3s;
        &:hover {
            background: var(--Background-color-secondary, #E8E8E8);
        }

        &:after {
            content:'';
            position: absolute;
            right:12px;
            top:19px;
            width:10px;
            height:10px;
            border-radius:50%;
            background: #75B757;
            // transform: translateY(-50%);
        }

        &.incomplete {
            &:after {
                background:#E9913D;
            }
        }
    }

    .plan-need-section {
        border-radius:16px;
        background:#fff;
        border: 1px solid var(--Border-primary, #E8E8E8);
        border-radius:16px;
        margin-bottom:40px;

        .checklist-header {
            border:none;
            border-bottom: 1px solid var(--Border-primary, #E8E8E8);
        }
        .checklist-item {
            border:none;
            border-bottom: 1px solid var(--Border-primary, #E8E8E8);
        }

        .referrals {
            padding: 24px;
        }
    }

    .checklist {
        margin-bottom:20px;

        .checklist-title-bar {
            width: 100%;
            height: 35px;
            padding: 5px 0px;
            transform: translateY(-5px);

            h5 {
                margin:0px;
            }

        }

        .checklist-title-input {
            width: calc(100% - 60px);
            font-weight: 700;
            font-size: 20px;
            padding: 2px 7px;
            height: 35px !important;
            color: #004259;
            transform: translateX(-5px);
            position: absolute;
            left: 39px;
            top: -1px;
        }

        // Checklist headers with no items
        // .checklist-header:not(:has(+ .checklist-item)) {
        //     border-radius:16px;
        // }

        // .checklist-header:has(+ .form-add-checklist-item.show) {
        //     border-radius:16px 16px 0 0;
        // }

        // .checklist-item:last-of-type {
        //     border-radius:0px 0px 16px 16px;
        // }

        // .checklist-item:last-of-type:has(+ .form-add-checklist-item.show) {
        //     border-radius:0px;
        //     margin-bottom:0px;
        // }

        .form-add-checklist-item {
            display: flex;
            flex-wrap: wrap;
            padding: 24px;
            justify-content: space-between;
            gap: 17px;
            border-bottom: 1px solid var(--Border-primary, #E8E8E8);
            display:none;
            &.show {
                display:block;
            }
        }

        .task-description {
            width: 100%;
            padding-left: 47px;
            padding-top:15px;
            color:$brand-light;
        }
    }

    .btn-add-task {
        display:block;
        margin-top:10px;
        padding:15px 24px;
    }

    .form-edit-task {
        width:100%;
        display:none;
        .form-top-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }
    }

    .btn-open-client-modal {
        cursor: pointer;
    }

    #client-search-list {
        .client-search-result {
            display: flex;
            gap: 10px;
            padding: 7px;
            margin: 10px 0 0;
            border: 1px solid #e8e8e8;
            border-radius: 5px;
            cursor: pointer;
            align-items: center;
            background: #ebebeb;
            transition: background 0.3s;
            &:hover {
                background: #f0f0f0;
            }
        }
    
        .no-results {
            margin-top: 10px;
            color: #004259;
        }
    }

    .plan-tile-wrap {
        position:relative;
        .list-menu-wrap {
            position:absolute;
            right:24px;
            top:18px;
        }
    }

    .btn-toolbar {
        justify-content:center;
    }

    #AddReferralForm_AddReferralForm, #CreateEditExpenseForm_CreateEditExpenseForm {
        .btn-toolbar {
            justify-content:flex-start;
        }
    }

    .expenses-table {
        border-radius:10px;
        overflow: hidden;

        .link-expand-row {
            &.expanded {
                .fa-angle-down {
                    transform: rotate(180deg);
                }
            }
        }

        .expense-details-row {
            display:none;
            background:#F7F7F7;
            &.expanded {
                display:table-row;
            }
        }
    }

    .referral-view-toggle {
        display:flex;
        border-radius:30px;
        background:#E8E8E8;
        a {
            display:inline-flex;
            justify-content:center;
            align-items:center;
            color:$brand-dark;
            width:40px;
            height:40px;
            text-decoration:none;
            transition:all .2s;
            border-radius:30px;
            &.active {
                background:$brand-dark;
                color:#fff;
            }
            i {
                pointer-events: none;
            }
        }
    }

}


.PlanViewPage{
    padding-top: 0;
    padding-left: 0;

    .view-container{
        border-radius:16px;
        background:#fff;
        border: 1px solid var(--Border-primary, #E8E8E8);
        border-radius:16px;
        margin:20px 0;
        padding: 24px 0;
    }

    .bb{
        border:none;
        border-bottom: 1px solid var(--Border-primary, #E8E8E8);
    }

    .view-title{
        padding: 0 24px;
    }

    .view-item{
        padding: 24px;
    }

    .item-referral{
        padding: 0 24px;
    }

    .accordion{
        border: none;
        --bs-accordion-border-color: transparent;
        border-radius: 0;

        .referral-card{
            border: none;
        }
        
        .accordion-button {
            position: relative;
            display: flex;
            width: 100%;
            padding: 0;
            font-size: 1rem;
            color: var(--bs-accordion-btn-color);
            text-align: left;
            background-color: var(--bs-accordion-btn-bg);
            border: 0;
            border-radius: 0;
            overflow-anchor: none;
            transition: var(--bs-accordion-transition);
            &:focus {
                box-shadow: none;
            }
        }

        .accordion-item{
            border-radius: 0;
        }

        .info-row {
            display:flex;
            align-items: center;
            padding-bottom:10px;
            gap:10px;
        }

        .icon {
            width:20px;
        }

        .hours-row {
            display:flex;
            width:200px;
            justify-content:space-between;
            font-size:14px;
            color:#7e7e7e;
        }
    }

}