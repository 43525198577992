.ClientMessagesPage, .ConnectorMessagesPage {

    .message-blue {
        position: relative;
        margin-bottom: 10px;
        margin-left: calc(54%);
        padding: 10px;
        background-color: $brand-blue-5;
        width: 45%;
        text-align: left;
        border: 1px solid $brand-blue-5;
        border-radius: 20px 20px 5px 20px;

        .message-content {
            color: $white;
         }
    }

    .message-white {
        position: relative;
        margin-bottom: 10px;
        margin-left: 20px;
        padding: 10px;
        background-color: $white;
        width: 45%;
        text-align: left;
        border: 1px solid $white;
        border-radius: 20px 20px 20px 5px;

        .message-content {
           color: $brand-blue-5
        }
    }

    .message-content {
        padding: 5px;
        margin: 5px;
    }

    .threads{
        margin-top: 15px;
    }

    .thread-container{
        padding: 24px 0;
        gap: 0;

        .heading{
            position: relative;
            padding: 0 24px 24px 24px;
            background-color: $white;

            h5{
                display: inline-block;
                margin-left: 20px;
            }
        }

        #message-container {
            background-color: #f7f7f7;
    
            .thread-messages{
                padding: 24px 0;
            }
        }

        .message-input{
            padding: 23px 23px 0 23px;
            border-top: 1px solid var(--Border-primary, #E8E8E8);

            .sendMessageText{
                border: none;
                box-shadow: none;
            }
        }
    }

    .thread-tile {
        padding: 13px;
        cursor: pointer;

        .thread{
            position: relative;

            .unread-message{
                background-color: #46BADB;
                height: 8px;
                width: 8px;
                border-radius: 4px;
                position: absolute;
                right: 0;
                top:18px
            }

            .thread-age{
                height: 100%;
                padding-top: 20px;
                color:rgba(0, 66, 89, 0.40);
                font-size: 14px;
                font-family: Inter;
                padding-right: 20px;
            }
            .thread-name{
                font-size: 14px;
                font-family: Inter;
                font-weight: 500;
                color: #004259;
            }
            .thread-message{
                font-size: 14px;
                font-family: Inter;
                font-weight: 500;
                color: rgba(0, 66, 89, 0.40);
            }
        }

        &:hover{
            background-color: #E8E8E8;
            border-radius: 20px;
        }
    }

    .circle-link {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #E8E8E8;
        color: $brand-blue-5;
        text-align: center;
        line-height: 40px;
    }

    #new-message {
        position: absolute;
        top: 24px;
        right: 24px;
    }

    .new-title{
        font-size: 18px;
        font-family: Inter;
        font-weight: 500;
        color: #004259;
        margin-bottom: 10px;
    }

    .btn-cirlce{
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #E8E8E8;
        color: $brand-blue-5;
        text-align: center;
        line-height: 40px;
        border: none;
    }

    .archive-dropdown{
        position: absolute;
        right: 23px;
        top: 0;

        #archive-thread-dropdown{
            display: none;
            border: 1px solid #E8E8E8;
            border-radius: 5px;
            padding: 10px;
            position: absolute;
            top: 40px;
            right: 0;
            z-index: 1;
            display: none;
            width: 200px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        &:hover{
            #archive-thread-dropdown{
                display: block;
                width: 188px;
                height: 55px;
            }
        }
    }

    .dropdown-item{
        padding: 5px 0;
        &:hover{
            background-color: $white;
        }
    }

    .user-initials-avatar {
        display: inline-block;
        padding: 8px 10px;
    }

}