.CalendarPage {

    #calendar_next{
        color: $brand-blue-5;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        padding: 4px 12px;
        margin-bottom: 27px;
    
        &:hover{
            color: $brand-blue-5;
            background-color: #E8E8E8;
        }
    }

    #calendar_prev{
        color: $brand-blue-5;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        padding: 4px 10px;
        margin-bottom: 27px;
        margin-left: 27px;
    
        &:hover{
            color: $brand-blue-5;
            background-color: #E8E8E8;
        }
    }

    #Calendar {
        background:none;

        thead{
            background:none;
            border: none;
            th {
                background:none;
                color:$brand-blue-5;
                text-align:right;
                font-weight:400;
                padding-right: 10px;
                font-family: Inter;
                font-size: 14px;

                &.Sun, &.Sat {
                    color: #999999;
                }
            }
            
        }
        
        tbody{
            td {
                border:1px solid #E8E8E8;
                height:105px;
                padding:7px 10px;
                background:white;
                vertical-align:top;
                width: 14.28%;

                &.today {
                    background: #F5F5F5;
                }

                .Date {
                    color:#004259;
                    text-align: right;
                }

                &.Sunday, &.Saturday {
                    background-color: #E8E8E8;
                }

                &.lastmonth{
                    .Date {
                        color: #7E7E7E;
                    }
                }

                .Event {
                    border-radius: 4px;
                    position: relative;
                    padding: 2px 5px;
                    margin-bottom: 4px;
                    font-size: 12px;
                    font-family: Inter;

                    .title{
                       color: #004259
                    }

                    .time{
                        color: rgba(0, 66, 89, 0.70);
                        float: right;
                    }

                    &:hover {
                        cursor:pointer;
                    }
                }
            }
        }
    }


    #EventForm{
        .row{
            padding: 10px 0; 

            &.bb{
                border-bottom: 1px solid #E8E8E8;
            }
        }
    }
}


input[type=time] {
    border-radius: 10px;
    border: 1px solid var(--Border-primary, #E8E8E8);
    padding: 10px;
    width: 100%;
    color: var(--Text-body, rgba(0, 66, 89, 0.7));
}