// List menu - Expands from an ellipsis button
.list-menu-wrap {
    position: relative;
    z-index:25;
    width:32px;

    &.open {
        z-index:30;
    }

    .list-menu {
        display:none;

        &.show {
            display:block;
        }
    }
    &.large, &.lg {
        .btn-expand-menu {
            width:40px;
            height:40px;
        }
        .list-menu-wrap {
            width:40px;
            flex-shrink: 0;
        }
        .list-menu {
            top:44px;
            width:250px;
        }
    
    }
    &.xl {
        width:48px;
        .btn-expand-menu {
            width:48px;
            height:48px;
            background: #E8E8E8;
            &:hover {
                background: #D8D8D8;
            }
        }
        .list-menu-wrap {
            width:48px;
            flex-shrink: 0;
        }
        .list-menu {
            top:58px;
            width:220px;
        }
    }
    &.fluid {
        width:100%;
    }
    &.short {
        .list-menu {
            width:150px;
        }
    }

    &.align-left {
        .list-menu {
            right:auto;
            left:0;
        }
    }
    &.align-top {
        .list-menu {
            bottom:calc(100% - 15px);
            top:unset;
        }
    }
}

.btn-expand-menu {
    position: relative;
    z-index: 1;
    width:32px;
    height:32px;
    border-radius:50%;
    display:flex;
    align-items:center;
    justify-content:center;
    background:transparent;
    border:none;
    color:$brand-dark;
    text-decoration:none;
    transition:.3s all;
    cursor: pointer;
    &:hover, &.active {
        background: var(--Background-color-secondary, #E8E8E8);
    }
    &:before {
        content: "\f141";
        font-family: 'Font Awesome 6 Pro';
    }

}

.list-menu {
    @extend .module-container;
    width:185px;
    padding:12px;
    position:absolute;
    top:35px;
    right:0;
    z-index:5;
    gap:0px;

    a, .list-menu-link {
        display:flex;
        align-items:center;
        justify-content:space-between;
        text-decoration:none;
        font-weight: 400;
        font-size:14px;
        margin:1px 0;
        padding:5px 10px;
        border-radius:5px;
        transition:.3s all;
        color:$brand-dark;
        cursor: pointer;
        &:hover {
            background: var(--Background-color-primary, #F7F7F7);
        }
        i {
            width: 20px;
            flex-shrink: 0;
            text-align: center;
        }
    }
}

.list-menu-shade {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0);
    z-index: 10;
    top: 0px;
    left: 0px;
}