#mobile-header {
    position:relative;
    display:block;
    background:#fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    width:100%;
    margin-bottom:40px;

    @include media-breakpoint-up(md) {
        display:none;
    }

    .mobile-logo {
        padding:12px 20px;
    }
    #btn-mobile-nav, #btn-close-mobile-nav {
        padding:14px 20px;
        font-size:20px;
    }

    .mobile-nav {
        position:fixed;
        left:0px;
        top:0px;
        background:rgba(0,0,0,.5);
        width:100%;
        height:100vh;
        z-index: 20;
        transform: translateX(-100%);
        transition: all .4s;

        &.open {
            transform: translateX(0%);
        }
    }
    header {
        display:flex;
        justify-content:space-between;
    }

    .mobile-nav-inner {
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        position:absolute;
        left:0px;
        top:0px;
        background:#fff;
        width:100%;
        max-width:320px;
        height:100%;
        z-index:20;
    }
    nav {
        padding:20px 15px 0px;
        ul {
            list-style-type:none;
            padding:0px;
            margin:0px;
            li {
                a {
                    display:flex;
                    gap:10px;
                    border-radius:10px;
                    padding:10px;
                    color:$brand-dark;
                    text-decoration:none;
                    transition:all .2s;
                    &:hover, &.active {
                        background:#F5F5F5;
                    }
                }
            }
        }
        
    }
}


.nav-bar {
    position:fixed;
    left:0px;
    top:0px;
    display: inline-flex;
    height: 100%;
    min-height:600px;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    background:#fff;
    z-index: 10;

    .logo {
        display:block;
        margin:0 auto;
    }

    .main-nav {
        margin:0px;
        padding:0px;
        list-style-type:none;
        a {
            display:flex;
            justify-content:center;
            width:40px;
            height:40px;
            align-items:center;
            border-radius:50%;
            transition:all .2s;
            margin-bottom:17px;
            &.active, &.current, &:hover {
                background:#eeeeee;
            }
        }
    }

    .secondary-nav {
        list-style-type:none;
        padding:25px 0;
        border-top:1px solid #E8E8E8;
        border-bottom:1px solid #E8E8E8;
        margin:0px;
        li:last-child {
            a {margin-bottom:0px;}
        }
        a {
            display:flex;
            justify-content:center;
            width:40px;
            height:40px;
            align-items:center;
            border-radius:50%;
            transition:all .2s;
            margin-bottom:17px;
            &.active, &:hover {
                background:#eeeeee;
            }
        }

    }

}

.breadcrumbs {
    font-size:14px;
    padding-bottom:24px;

    a {
        color:$brand-dark;
        text-decoration:none;

        &:hover {
            text-decoration:underline;
        }
    }

    i {
        padding:0 12px;
    }
}



.tab-set-1 {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    a {
        display:block;
        padding: 10px 20px;
        border-radius: 10px;
        color:rgba($brand-dark, .4);
        font-weight: 500;
        text-decoration:none;
        &.active {
            color:$brand-dark;
            text-decoration:none;
        }      
        &:hover {
            color:$brand-dark;
            text-decoration:none;
        } 
    }
}

.tab-link {
    color: var(--Text-secondary, #7E7E7E);
    font-size: 16px;
    font-weight: 400;
    padding:8px 0px;
    margin:0px 17px;
    text-decoration:none;
    cursor:pointer;

    &:first-child {
        margin-left:0px;
    }
    &:last-child {
        margin-right:0px;
    }
    &.active {
        color:$brand-dark;
        border-bottom:1px solid $brand-dark;
    }
    &:hover {
        border-bottom:1px solid #7E7E7E;
    }
}

.action-link {
    display: flex;
    gap: 17px;
    border-radius: 10px;
    border: 1px solid var(--Border-primary, #E8E8E8);
    background: var(--Background-color-white, #FFF);
    padding:17px 24px;
    color:$brand-dark;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom:10px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
        background: rgba(70, 186, 219, 0.25);
    }
    &.sm {
        padding:10px 24px;
        gap:12px;
    }
}

// Header Top Nav
.top-nav {
    padding-bottom:25px;
    column-gap:8px;
    a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width:42px;
        height:42px;
        border-radius:50%;
        // background: #F5F5F5;
        background:#E8E8E8;
        transition:.3s ease;

        &:hover {
            background:#fff;
        }
        svg {
            fill: $brand-dark;
            width:10px;
        }
    }
}


.nav-bar, .top-nav {
    display:none;
    @include media-breakpoint-up(md) {
        display:inline-flex;
    }
}

.user-profile-wrap {
    position: relative;
    padding-top:25px;
    &:hover .user-nav-popover {
        display: flex;
    }
}

.user-nav-popover {
    position:absolute;
    width:175px;
    top:-100px;
    z-index: 50;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid var(--Border-primary, #E8E8E8);
    background: var(--Background-color-white, #FFF);
    padding:10px 0;
    display:none;
    
    a {
        font-weight:500;
        font-size:18px;
        text-decoration:none;
        color:$brand-dark;
        padding:8px 24px;
        &:hover {
            background:rgba(70,186,219,.2);
        }

        &.signout {
            color:$red;
        }

        .far {
            width:20px;
            margin-right:15px;
        }
    }
}
