.initials {
    display:inline-flex;
    justify-content:center;
    align-items:center;
    width: 72px;
    height:72px;
    border-radius:50%;
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    background:$primary;
    text-decoration:none !important;
}
.module-container, .tile {
    display: flex;
    padding: 24px;
    margin-bottom:22px;
    flex-direction: column;
    gap: 17px;
    border-radius: 10px;
    border: 1px solid var(--Border-primary, #E8E8E8);
    background: var(--Background-color-white, #FFF);

    hr {
        width:100%;
        margin:0px;
        // border: 1px solid var(--Border-primary, #E8E8E8);
    }

    .module-header {
        position: relative;
        padding-bottom:8px;
        &:before {
            content:'';
            border-bottom: 1px solid var(--Border-primary, #E8E8E8);
            position: absolute;
            width:calc(100% + 48px);
            left:-24px;
            bottom:0px;

        }
    }

    .module-nav {
        margin:0 -10px;
        a {
            display: block;
            padding:14px 17px;
            border-radius:6px;
            color:$brand-dark;
            text-decoration:none;
            transition:all .3s;
            margin:4px 0;
            font-weight:400;

            &.active, &:hover {
                background:rgba($brand-blue-1, .15);
                color:$brand-blue-2;
            }
        }
    }

    .link-arrow {
        position: relative;
        display: block;
        align-items:center;
        padding:20px 0;
        width: 100%;
        color: $brand-dark;
        font-size: 16px;
        font-weight: 600;
        text-decoration:none;
        border-bottom:1px solid #D9D8D6;
        &:after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxMSAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjEzMjMgNy40Njg3NUMxMC40MTM2IDcuNzgxMjUgMTAuNDEzNiA4LjI1IDEwLjEzMjMgOC41MzEyNUw0LjEzMjMyIDE0LjUzMTJDMy44MTk4MiAxNC44NDM4IDMuMzUxMDcgMTQuODQzOCAzLjA2OTgyIDE0LjUzMTJDMi43NTczMiAxNC4yNSAyLjc1NzMyIDEzLjc4MTIgMy4wNjk4MiAxMy41TDguNTM4NTcgOC4wMzEyNUwzLjA2OTgyIDIuNTMxMjVDMi43NTczMiAyLjI1IDIuNzU3MzIgMS43ODEyNSAzLjA2OTgyIDEuNUMzLjM1MTA3IDEuMTg3NSAzLjgxOTgyIDEuMTg3NSA0LjEwMTA3IDEuNUwxMC4xMzIzIDcuNDY4NzVaIiBmaWxsPSIjMDA0MjU5Ii8+Cjwvc3ZnPgo=");
        }
        &:hover {
            text-decoration: underline;
            .initials {
                text-decoration:none !important;
            }
        }

        .initials {
            margin-right:14px;
            text-decoration:none !important;
            &:hover {
                text-decoration:none !important;
            }
        }
    }
}

// Link Container
.link-container {
    gap:0px;
    padding-bottom:0px;

    .link-arrow:last-child {
        border-bottom:0px;
    }
}


// Event Tile
.event-tile {
    position: relative;
    width:100%;
    border-radius:10px;
    display: flex;
    padding: 12px 24px;
    align-items: flex-start;
    gap: 29px;
    background:#E8E8E8;
    font-size:14px;
    font-weight:400;
    &:hover:after {
        opacity:.1;
    }

    &:after {
        content:"";
        position:absolute;
        width:100%;
        height:100%;
        left:0px;
        top:0px;
        border-radius:10px;
        background:#000;
        z-index:1;
        opacity:0;
        transition:.3s all;
    }


    .title {
        font-weight:600;
    }

    .date {
        text-align: center;
        text-transform:uppercase;
        font-size: 12px;
        font-weight: 500;
    }
    .day {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        display:block;
    }
}

// Stat Tiles
.stat-tiles-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.stat-tile {
    @extend .module-container;
    margin-bottom:0px;
    gap:17px;
    .label {
        color: #999;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
    }
    .value {
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
    }
    .title {
        color: #004259;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
    }
}

.link-arrow  {
    .user-initials-avatar, .user-photo {
        display: inline-flex;
        margin-right: 10px;
    }
}