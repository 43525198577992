// Override the accordion component
.accordion {
    margin-bottom:12px;

    .accordion-button {
        font-weight: 500;
        padding:30px 30px 30px 70px;

        &.clearpadding {
            padding: 1rem 1.25rem;
        }
    }
}

// Step accordion
.step-accordion {
    .step-accordion-header {
        padding:24px;
    }
    .header {
        font-size: 24px;
    }
    .subheader {
        color: var(--Text-body, rgba(0, 66, 89, 0.70));
        font-size: 16px;
        margin-bottom:10px;
    }

    .btn-update-checklist-item {
        cursor: pointer;
        &:hover i {
            color:$brand-blue-1;
        }
    }

    .accordion-item {
        position: relative;
    }

    .icon {
        position:absolute;
        z-index:10;
        left: 24px;
        top: 27px;
        width:25px;
        height:25px;
        border-radius:50%;
        border:2px solid #D9D8D6;

        &.complete {
            background:$brand-blue-1;
            border-color:$brand-blue-1;
            &:before {
                position: absolute;
                top: -12px;
                left: 4px;
                z-index: 4;
                width: 13px;
                display: block;
                height: 25px;
                content:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNNDM4LjYgMTA1LjRjMTIuNSAxMi41IDEyLjUgMzIuOCAwIDQ1LjNsLTI1NiAyNTZjLTEyLjUgMTIuNS0zMi44IDEyLjUtNDUuMyAwbC0xMjgtMTI4Yy0xMi41LTEyLjUtMTIuNS0zMi44IDAtNDUuM3MzMi44LTEyLjUgNDUuMyAwTDE2MCAzMzguNyAzOTMuNCAxMDUuNGMxMi41LTEyLjUgMzIuOC0xMi41IDQ1LjMgMHoiLz48L3N2Zz4=");
            }
        }
    
    }

    .accordion-body {
        padding-left:68px;
        font-size:14px;
        color: $body-color-light;
        p {
            color:$body-color-light;
        }
    }

    .grid-2-col, .grid-2-col-lg {
        @include media-breakpoint-up(lg) {
            grid-template-columns: 2fr 1fr;
        }
    }

}