.blog-header {
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    background:url(../../images/resources-header.jpg);
    background-size: cover;
    border-radius:10px;
    padding:20px;
    min-height:340px;
    color:#fff;
    text-align:center;
    margin-bottom:20px;

    h1 {
        font-size:40px;
    }

    p {
        max-width:750px;
        margin:0 auto;
    }
}

.post-tile {
    // height:100%;
    .icon {
        font-size: 40px;
        color: $brand-blue-1;
    }

    .title {
        font-size: 16px;
        margin-bottom:5px;
        font-weight:500;
    }
}

.liaison-banner {
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    background:url(../../images/liaison-help.jpg);
    background-size: cover;
    border-radius:10px;
    padding:20px;
    min-height:340px;
    color:#fff;
    text-align:center;
    margin:50px auto;

    .icon {
        font-size: 40px;
        margin-bottom:30px;
    }

    p {
        max-width:550px;
        margin:0 auto 20px;
    }
}


// Blog Post

.blog-container {
    max-width:960px;
}
.blog-post-header {
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    background:url(../../images/resources-header.jpg);
    background-size: cover;
    border-radius:10px;
    padding:24px;
    min-height:270px;
    color:#fff;
    text-align:left;
    margin:20px 0 50px;

    @include media-breakpoint-up(lg) {
        padding:40px 53px;
    }

    h1 {
        font-size:30px;
        margin:0px;
        padding:0px;

        @include media-breakpoint-up(md) {
            font-size:40px;
        }
    }

}

.blog-post-cta {
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    background:url(../../images/liaison-help.jpg);
    background-size: cover;
    border-radius:10px;
    padding:20px;
    min-height:240px;
    color:#fff;
    margin:50px auto;
    @include media-breakpoint-up(lg) {
        padding:40px 53px;
    }

}
