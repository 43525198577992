h1 {
    padding-bottom:24px;
}

// Text colors
.text-blue-1 {
    color:$brand-blue-1;
}
.text-blue-2 {
    color:$brand-blue-2;
}
.text-blue-3 {
    color:$brand-blue-3;
}
.text-blue-5, .text-dark-blue {
    color:$brand-blue-5;
}
.text-orange, .text-orange-1 {
    color:$brand-orange-1 !important;
}
.text-green, .text-green-1 {
    color:$brand-green-1;
}
.text-brand-light, .text-body-light {
    color: var(--Text-body, rgba(0, 66, 89, 0.70));
}
.text-red, .text-error {
    color:$red !important;
}
.text-gray {
    color: #7E7E7E;
}

.text-body-color-light {
    color: $body-color-light;
}

.text-left {
    text-align:left;
}

.text-light-red {
    color: var(--Text-body, rgba(243, 62, 48, 0.50));
}

.text-secondary, .text-neutral-light{
    color:$gray-500 !important;
}

.text-xs {
    font-size:12px !important;
}

.text-small, .text-sm {
    font-size:14px !important;
}
.text-reg, .text-rg {
    font-size:16px !important;
}
.text-medium, .text-md {
    font-size:18px !important;
}

.text-large, .text-lg {
    font-size:20px !important;
}

.text-xlarge, .text-xl {
    font-size:24px !important;
}

.text-xxlarge, .text-xxl {
    font-size:30px !important;
}

.weight-regular{
    font-weight: 400;
}
.weight-medium{
    font-weight: 500;
}
.weight-semibold{
    font-weight: 600;
}
.weight-bold{
    font-weight: 700;
}


// Background colors
.bg-blue-1 {
    background:$brand-blue-1 !important;
}
.bg-blue-2 {
    background:$brand-blue-2 !important;
}
.bg-blue-3 {
    background:$brand-blue-3 !important;
}
.bg-blue-5, .bg-blue-dark {
    background:$blue-dark !important;
}

.bg-orange {
    background:$orange !important;
}
.bg-green {
    background:$green !important;
}
.bg-green-20 {
    background: rgba($green, .2) !important;
}
.bg-blue-40 {
    background: rgba($blue, .4) !important;
}
.bg-orange-40 {
    background: rgba($orange, .4) !important;
}
.bg-off-white {
    background:$gray-200 !important;
}
.bg-gray-light {
    background:$gray-light !important;
}

.user-photo {
    width:40px;
    height:40px;
    min-width:40px;
    min-height:40px;

    &.sm {
        width:30px;
        height:30px;
        min-width:30px;
        min-height:30px;
    }

    &.lg {
        width:72px;
        height:72px;
        min-width:72px;
        min-height:72px;
    }
    &.xl {
        width:104px;
        height:104px;
        min-width:104px;
        min-height:104px;
    }
}

.user-initials-avatar {
    display:flex;
    align-items:center;
    justify-content:center;
    color:#fff;
    width:40px;
    height:40px;
    min-width:40px;
    min-height:40px;
    border-radius:50%;
    background:$brand-dark;
    font-size:17px;
    font-weight:500;
    text-decoration:none;
    &:hover {
        text-decoration:none;
    }

    &.sm, &.small {
        width:30px;
        height:30px;
        min-width:30px;
        min-height:30px;
        font-size:13px;
    }

    &.lg {
        width:72px;
        height:72px;
        min-width:72px;
        min-height:72px;
        font-size:24px;
    }
    &.xl {
        width:104px;
        height:104px;
        min-width:104px;
        min-height:104px;
        font-size:28px;
    }
    &.xxl {
        width:144px;
        height:144px;
        min-width:144px;
        min-height:144px;
        font-size:48px;
    }

    &.orange {
        background:$brand-orange-1;
    }
    &.green {
        background:$brand-green-1;
    }
    &.light-blue {
        background:$brand-blue-1;
    }
    &.medium-blue {
        background:$brand-blue-2;
    }
    &.dark-blue {
        background:$brand-blue-5;
    }
}

.pagination {
    display:flex;
    padding-top:32px;
    justify-content:space-between;
    a {
        display:inline-flex;
        justify-content:center;
        align-items:center;
        height:40px;
        width:40px;
        padding:12px;
        border-radius:30px;
        color:$brand-dark;
        text-decoration:none;
        transition:.3s all;
        &.active {
            background: var(--Color-Neutral-neutral-lightest, #E8E8E8);
        }
        &:hover {
            background:rgba(#E8E8E8, .5);
        }
    }
    .prev, .next {
        width:auto;
        padding:8px 20px;
        background: var(--Color-Neutral-neutral-lightest, #E8E8E8);
    }

}

.alert {
    border-radius:10px;

    &.alert-white {
        background:#fff;
        border:1px solid var(--Border-primary, #E8E8E8);
    }

    .btn-close {
        padding: 4px 5px;
        top: 20px;
        right: 22px;
    }
}

// Forms

select {
    background-color:#fff !important;
}


// General / Utilities
.user-photo, .circle {
    border-radius:50%;
    align-self:center;
}

.badge {
    display:flex;
    align-items:center;
    justify-content:center;
    border:1px solid $brand-blue-1;
    border-radius:10px;
    background:#46BADB33;
    color:$brand-dark;
    font-size:14px;
    font-weight:500;
    padding:20px;

    .number {
        font-size:32px;
        font-weight:700;
        padding-right:10px;
    }

    &.complete {
        background:$brand-blue-1;
    }

}

.cta-tile {
    padding-top:35px;
    padding-bottom:35px;
    text-align:center;
    h3 {margin-bottom:0px;}

    p {
        max-width:400px;
        margin:0 auto;
    }

    .icon {
        font-size:36px;
        color:$brand-blue-1;
    }
    
}

.icon-circle {
    display:inline-flex;
    align-items:center;
    justify-content:center;
    width:48px;
    height:48px;
    border-radius:50%;
    color:#fff;
    font-size:20px;

    &.icon-circle-lg {
        width: 88px;
        height: 88px;
        background: rgba(70, 186, 219, .2);
        font-size: 32px;
    }

    &.icon-danger {
        background:#FEF3F2;
        color:$red;
    }
}

.icon-double-layer {
    position: relative;
    i:first-child {
        color:#fff;
    }
    i:last-child {
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        color:$brand-dark;
    }
}

.icon-tile {
    display:inline-flex;
    border-radius: 10px;
    border: 2px solid var(--Border-primary, #E8E8E8);
    background: #FFF;
    transition:all .2s;
    padding:50px 0;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    text-align:center;
    text-decoration:none;
    color:$brand-dark;
    &.square {
        aspect-ratio : 1 / 1;
    }

    &.rectangle {
        flex-direction:row;
        text-align:left;
        align-items:flex-start;
        padding:24px;
        i[class*="fa"] { 
            font-size: 35px;
            padding-right:12px;
            color:$brand-blue-1;
        }

        p {
            font-size:14px;
            color:#006080;
            padding-top:5px;
        }
    
    }
    &:hover {
        border: 2px solid var(--Light-Blue-1, #46BADB);
    }

    .icon {
        display:flex;
        align-items:center;
        justify-content:center;
        width:88px;
        height:88px;
        border-radius:50%;
        background: rgba(70, 186, 219, 0.20);
        font-size: 32px;
        margin-bottom:15px;
    }
}

.status-dot-label {
    position: relative;
    font-size: 14px;
    padding-left: 16px;
    &:before {
        content: "";
        position: absolute;
        display: block;
        left: 0px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        top: 5px;
        background:#B7B7B7;
    }
    &.active:before {
        background: $green;
    }
}


.password-rule {
    color: rgba(0, 66, 89, 0.50);
    width: 100%;
    margin-bottom: 13px;

    span{
        margin-right: 17px;
        &::before{
            font-family: 'Font Awesome 6 Pro';
            font-weight: 900;
            -webkit-font-smoothing: antialiased;
            content: '\f111';
            color: $gray-500
        }
    }

    &.found{
        color:#18ce9c;

        span{
            &::before{
                font-family: 'Font Awesome 6 Pro';
                font-weight: 900;
                -webkit-font-smoothing: antialiased;
                content: '\f00c';
                color:#18ce9c;
            }
    
        }
    }
}

.row.row-border {
    position: relative;
    padding-top:15px;
}

.row.row-border:after {
    content: '';
    width: calc(100% + 24px);
    height: 1px;
    background: #e8e8e8;
    position: absolute;
    left: -12px;
    top: 0px;
}


.modal-backdrop:last-child:not(:first-child) {
    display: none;
}

.modal-warning {
    .header-icon-wrap {
        background-color:#FEF3F2 !important;
    }
}
