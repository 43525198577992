// Progress Bar
.progress-label {
    width:42px;
    font-size: 14px;
    color: var(--Text-secondary, #7E7E7E);
}
.progress-bar {
    position: relative;
    width:100%;
    height: 8px;
    background: rgba($brand-blue-1, .2);
    border-radius: 4px;
    .progress, .progress-bar-inner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;
        background: $brand-blue-1;
        border-radius: 4px;
        transition:all .4s;
    }
}
