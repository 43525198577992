.notifications-panel {
    display:none;
    position:fixed;
    z-index:50;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    background:#fff;
    overflow:hidden;
    border: 1px solid var(--Border-primary, #E8E8E8);
    box-shadow: 176px 0px 49px 0px rgba(0, 0, 0, 0.00), 113px 0px 45px 0px rgba(0, 0, 0, 0.01), 63px 0px 38px 0px rgba(0, 0, 0, 0.02), 28px 0px 28px 0px rgba(0, 0, 0, 0.03), 7px 0px 16px 0px rgba(0, 0, 0, 0.04);

    @include media-breakpoint-up(md) {
        top:20px;
        left:100px;
        width:100%;
        max-width:390px;
        height:calc(100% - 40px);    
        border-radius:10px;
    }

    &.open, &.show {
        display:block;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 24px;

        .heading {
            font-size:18px;
            line-height:20px;
            font-weight:600;
            padding-left:28px;
        }
        
        

        .btn-close-notifications {
            font-size: 14px;
            color:$brand-dark;
            padding: 5px;
            opacity: 1;
        }
    }

    .tab-nav {
        padding:12px 24px;
        background: var(--Background-color-primary, #F7F7F7);
        border-right: 1px solid var(--Border-primary, #E8E8E8);
        border-bottom: 1px solid var(--Border-primary, #E8E8E8);

        a {
            color: var(--Text-body, rgba(0, 66, 89, 0.70));
            text-decoration:none;
            padding-right:25px;
        }

        .active {
            color:$brand-dark;
            font-size: 16px;
            font-weight: 500;
            text-decoration:underline; 
        }
    }

    .notification-list {
        position: relative;
        height: calc(100% - 120px);
        overflow-y: auto;
    }
    .notification-item {
        padding:24px;
        border-bottom: 1px solid var(--Border-primary, #E8E8E8);

        &:hover {
            background: var(--Background-color-primary, #F7F7F7);
        }

        .message-title {
            color: var(--Text-primary, #004259);
            font-size: 16px;
            font-weight: 600;
            margin-bottom:5px;
            display: flex;
            justify-content: space-between;
        }

        .message-preview {
            color: var(--Text-body, rgba(0, 66, 89, 0.70));
            font-size: 12px;
            font-weight: 400;
            margin-bottom:10px;
        }
        a {
            color:$brand-blue-1;
            font-size: 14px;
            font-weight: 500;
        }

        .timestamp {
            color: var(--Text-secondary, #7E7E7E);
            font-size: 12px;
            font-weight: 400;
        }
    }
}

.icon-bell {
    position: relative;
    font-size:20px;
    text-decoration: none;
    color: var(--Text-primary, #004259);

    &.unread {
        &:before {
            content: '';
            position: absolute;
            top: 5px;
            right: -1px;
            width: 9px;
            height: 9px;
            background: $brand-blue-1;
            border:1.5px solid #fff;
            border-radius: 50%;
        }
    }

    &.btn-show-notifications{
        &.unread {
            &:before {
                top: 8px;
                right: 8px;
            }
        }
    }
}
