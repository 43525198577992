.checklist {
    .checklist-header {
        @extend .module-container;
        border-radius:16px 16px 0 0;
        margin:0px;

        .header-title {
            display:flex;
            justify-content:space-between;

            i {
                width:42px;
            }
        }
    }

    .checklist-item {
        display: flex;
        flex-wrap: wrap;
        padding: 14px 24px;
        justify-content: space-between;
        gap: 17px;
        border: 1px solid var(--Border-primary, #E8E8E8);
        border-top:none;
        background: var(--Background-color-white, #FFF);

        .checklist-item-inner {
            display: flex;
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;
        }
        
        .title {
            align-items: center;
            display: flex;
            font-weight:600;
        }

        &.last {
            border-radius:0 0 16px 16px;
            margin-bottom:15px;
        }
    }
    .checklist-item-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .checklist-item-body {
        width: 100%;
        padding-left: 40px;
        p {
            color: var(--Text-body, rgba(0, 66, 89, 0.70));

        }
    }    

    // Write a custom styled checkbox
    input[type="checkbox"] {
        position: relative;
        left:4px;
        display: inline-block;
        cursor: pointer;
        transition: background-color 0.3s;
        margin-right:34px;
        

        &:after {
            content: "";
            position: absolute;
            top: -5px;
            left: -2px;
            width: 25px;
            height: 25px;
            background-color: #fff;
            border: 1px solid #46badb;
            border-radius: 50%;            
            border: 1px solid $brand-blue-1;
        }

        &:checked {
            &:after {
                background-color: $brand-blue-1;
            }
            &:before {
                position:absolute;
                left:4px;
                top:-3px;
                width: 12px;
                z-index:4;
                content:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNNDM4LjYgMTA1LjRjMTIuNSAxMi41IDEyLjUgMzIuOCAwIDQ1LjNsLTI1NiAyNTZjLTEyLjUgMTIuNS0zMi44IDEyLjUtNDUuMyAwbC0xMjgtMTI4Yy0xMi41LTEyLjUtMTIuNS0zMi44IDAtNDUuM3MzMi44LTEyLjUgNDUuMyAwTDE2MCAzMzguNyAzOTMuNCAxMDUuNGMxMi41LTEyLjUgMzIuOC0xMi41IDQ1LjMgMHoiLz48L3N2Zz4=");
            }
        }

    }


}