form {

    input[type="submit"] {
        @extend .btn;
        @extend .btn-primary;
        @extend .btn-lg;
    }

    .btn {
        @extend .btn-lg;
    }

    .btn-toolbar {
        gap:10px;
    }

    label {
        font-size: 14px;
        font-weight: 500;
        padding-bottom:4px;
    }

    .middleColumn {
        padding-bottom:20px;
    }

    .message {
        @extend .alert;

        &.good {
            @extend .alert;
            @extend .alert-success;
        }

        &.bad {
            @extend .alert;
            @extend .alert-danger;
        }

    }

    ::placeholder {
        color: var(--Text-body, rgba(0, 66, 89, 0.30));
    }
}

input[type="text"], input[type="email"], input[type="password"],input[type="number"], input[type="search"], select {
    height:48px;
    border-radius: 10px;
    border: 1px solid var(--Border-primary, #E8E8E8);
    padding:10px;
    width:100%;
    color: var(--Text-body, rgba(0, 66, 89, 0.70));
}
select {
    padding-right:40px;
    background-image:url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNDY4NzUgMTQuNTMxMkwzLjQ2ODc1IDguNTMxMjVDMy4xNTYyNSA4LjI1IDMuMTU2MjUgNy43ODEyNSAzLjQ2ODc1IDcuNDY4NzVDMy43NSA3LjE4NzUgNC4yMTg3NSA3LjE4NzUgNC41MzEyNSA3LjQ2ODc1TDEwIDEyLjk2ODhMMTUuNDY4OCA3LjVDMTUuNzUgNy4xODc1IDE2LjIxODggNy4xODc1IDE2LjUzMTIgNy41QzE2LjgxMjUgNy43ODEyNSAxNi44MTI1IDguMjUgMTYuNTMxMiA4LjUzMTI1TDEwLjUgMTQuNTMxMkMxMC4yMTg4IDE0Ljg0MzggOS43NSAxNC44NDM4IDkuNDY4NzUgMTQuNTMxMloiIGZpbGw9IiMwMDQyNTkiLz4KPC9zdmc+");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-color:transparent;
    -webkit-appearance: none;
    -moz-appearance: none;              
}


input[type="date"] {
    border-radius: 10px;
    border: 1px solid var(--Border-primary, #E8E8E8);
    padding:10px;
    width:100%;
    color: var(--Text-body, rgba(0, 66, 89, 0.70));
}

textarea {
    border-radius: 10px;
    border: 1px solid var(--Border-primary, #E8E8E8);
    padding:10px;
    width:100%;
    color: var(--Text-body, rgba(0, 66, 89, 0.70));
}
.form-step, fieldset {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    div.field {
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        .field.one-half {
            width: calc((100% / 2) - 10px);
        }
        .field.one-third {
            width: calc((100% / 3) - 10px);
        }
        .field.two-thirds {
            width: calc(67% - 10px);
        }
        .field.one-quarter {
            width: calc((100% / 4) - 10px);
        }
        .field.three-quarters {
            width: calc(75% - 10px);
        }
    }    
}

.search {
    position: relative;
    &:before {
        position: absolute;
        left:15px;
        top:16px;
        content:url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjUgNi41QzExLjUgNC43MTg3NSAxMC41MzEyIDMuMDkzNzUgOSAyLjE4NzVDNy40Mzc1IDEuMjgxMjUgNS41MzEyNSAxLjI4MTI1IDQgMi4xODc1QzIuNDM3NSAzLjA5Mzc1IDEuNSA0LjcxODc1IDEuNSA2LjVDMS41IDguMzEyNSAyLjQzNzUgOS45Mzc1IDQgMTAuODQzOEM1LjUzMTI1IDExLjc1IDcuNDM3NSAxMS43NSA5IDEwLjg0MzhDMTAuNTMxMiA5LjkzNzUgMTEuNSA4LjMxMjUgMTEuNSA2LjVaTTEwLjUzMTIgMTEuNjI1QzkuNDA2MjUgMTIuNSA4IDEzIDYuNSAxM0MyLjkwNjI1IDEzIDAgMTAuMDkzOCAwIDYuNUMwIDIuOTM3NSAyLjkwNjI1IDAgNi41IDBDMTAuMDYyNSAwIDEzIDIuOTM3NSAxMyA2LjVDMTMgOC4wMzEyNSAxMi40Njg4IDkuNDM3NSAxMS41OTM4IDEwLjU2MjVMMTUuNzgxMiAxNC43MTg4QzE2LjA2MjUgMTUuMDMxMiAxNi4wNjI1IDE1LjUgMTUuNzgxMiAxNS43ODEyQzE1LjQ2ODggMTYuMDkzOCAxNSAxNi4wOTM4IDE0LjcxODggMTUuNzgxMkwxMC41MzEyIDExLjYyNVoiIGZpbGw9IiM3RTdFN0UiLz4KPC9zdmc+");
    }
    input[type="text"] {
        padding-left:40px;
    }
}


.dropzone-field, .file-dropzone {
    input[type="file"] {
        width:100%;
        &:after {
            content: url('../../images/file-upload.png');
            display:block;
        }
    }
}

.profile-photo-dropzone {
    input[type="file"] {
        width:100%;
        &:after {
            content: url('../../images/file-dropzone.svg');
            border: 1px solid #dfdfdf;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            min-width: 300px;
            background: white;
        }
    }
}
.client-file-dropzone {
    input[type="file"] {
        font-weight: 500;
        width:100%;
        &:after {
            content: url('../../images/client-file-dropzone.svg');
            // border: 1px solid #dfdfdf;
            // border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            min-width: 300px;
            background: white;
        }
    }
}

::file-selector-button {
    display: none;
}


  .round-checkbox {
    position: relative;
    display: inline-block;
  }

  .round-checkbox label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    width: 28px;
    display: block;
  }

  .round-checkbox label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 8px;
    opacity: 0;
    position: absolute;
    top: 9px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .round-checkbox input[type="checkbox"] {
    visibility: hidden;
    display: none;
    opacity: 0;
  }

  .round-checkbox input[type="checkbox"]:checked + label {
    background-color: $brand-blue-1;
    border-color: $brand-blue-1;
  }

  .round-checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }



  // Specific form styles and overrides
  #CommunicationPreferencesForm, #ClientCommunicationPreferencesForm {

    p {
        width:100%;
        flex-wrap: wrap;
    }
    .optionset {
        list-style-type:none;
        margin:0px;
        padding:0px;

        li {
            position:relative;
            display:inline-block;
            margin-right:10px;
        }

        input {
            position:absolute;
            opacity:0;
        }

        input + label {
            @extend .btn;
            @extend .btn-secondary;
            @extend .btn-lg;
        }

        input:checked + label {
            @extend .btn;
            @extend .btn-primary;
            @extend .btn-lg;
            &:hover {
                color:#fff;
                background:lighten($brand-blue-1, $amount: 10);
            }
        }

        

    }
  }

#ClientFileForm {
    position:relative;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    padding:20px;
    margin-bottom:20px;

    .btn-toolbar {
        justify-content:center;
    }

    &.has-type-selection {
        padding:20px 20px 90px;

        #ClientFileForm_Type_Holder {
            position:absolute;
            width:260px;
            left:calc(50% - 210px);
            bottom:10px;
    
        }
    
        .btn-toolbar {
            position:absolute;
            left:calc(50% + 80px);
            bottom:30px;
        }
    
    }

}


#ShareOptOutForm_ShareOptOutForm_Option{ list-style:none }
#ShareOptOutForm_ShareOptOutForm_Option input{ display:inline; margin-right:18px;}
#ShareOptOutForm_ShareOptOutForm_Option label{ display:inline; }
#ShareOptOutForm_ShareOptOutForm_Option li{ margin-bottom:10px; }
#ShareOptOutForm_ShareOptOutForm .btn-toolbar {align-items: center; justify-content: center;}
#ShareOptOutForm_ShareOptOutForm_action_doSubmit { margin-top: 18px; width: 250px; }