.plan-tile {
    @extend .module-container;
    position:relative;
    gap:5px;
    margin:0px;
    text-decoration:none;
    color:$brand-dark;
    .plan-tile-inner {
        display:flex;
        align-items:center;
        justify-content:space-between;
        height:100%;
    }
    h5 {
        margin-bottom:7px;
        color:$brand-dark;
    }
    a {
        text-decoration:none;
    }
    .plan-specs {
        color: var(--Text-secondary, #7E7E7E);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin:0px;
    }

    .progress-bar {
        margin-top:10px;
        height:8px;
    }

}

.referral-card, .referral-list-item {
    position: relative;
    display:flex;
    flex-direction:row;
    gap:20px;

    .org-image {
        align-self:flex-start;
    }

    .card-body {
        padding-right:30px;
    }

    .title {
        font-size:20px;
        line-height:24px;
        font-weight:600;
        margin-bottom:5px;
    }

    p {
        font-size:14px;
        color: var(--Text-body, rgba(0, 66, 89, 0.70));
    }

    .list-menu-wrap {
        position:absolute;
        right:24px;
        top:18px;
    }
}

.referral-card {
    @extend .module-container;
    margin-bottom:7px;

    &.no-border {
        border-top:none;
        border-left:none;
        border-right:none;
        border-radius:0px;
    }
}


.module-find-referrals {
    @extend .module-container;
    padding-bottom:0px;
    gap:0px;

    .module-header {
        padding-bottom:20px;

        .header-left {
            display:flex;
            width:100%;
            max-width:900px;
        }

        .icon-circle {
            flex-shrink:0;
            margin-right:24px;
        }
    }

    .find-referral-filters {
        display:flex;
        justify-content:space-between;
        align-items:center;
        padding-top:20px;
        select {
            width:180px;
            margin-right:5px;
            padding-left:15px;
            border-radius: 30px;
            border: 1px solid var(--Color-Neutral-neutral-lightest, #E8E8E8)!important;
            background-color: var(--Color-Neutral-neutral-lightest, #E8E8E8) !important;
        }
    }
    

    .module-body {
        display:flex;
        margin: 0 -24px;
    }

    .list-col {
        height:650px;
        overflow:auto;
        &::-webkit-scrollbar {
            width: 7px;
            height: 100%;
            background: $gray-300;
            border-radius:3px;
        }
        
        &::-webkit-scrollbar-thumb {
            background: $gray-500;
            border-radius:3px;
        }

        @include media-breakpoint-up(lg) {
            width:50%;
        }
    }

    .referral-list-item {
        padding:20px;
        border-bottom:1px solid $gray-light;
    }

    .map-col {
        position: relative;
        background-color:$brand-dark;
        height:100%;
        min-height:650px;
        width:100%;
        @include media-breakpoint-up(lg) {
            width:50%;
        }

        #map-canvas {
            position:absolute !important;
            height:100%;
            width:100%;
        }
    }

    .card-list-view {
        display:none;
    }

    &.list-view {

        .list-col {
            @include media-breakpoint-up(lg) {
                width:100%;
            }
        }
        .map-col {
            display:none;
        }

        .referral-card .org-image {
            display:none;
        }

        // Hide Card Map View
        .card-body {
            display:none;
        }
        .card-list-view {
            position:relative;
            display:block;
            width:100%;
            &.expanded {
                .btn-expand-referral {
                    transform:rotate(180deg);
                }
                .details {
                    display:block;
                }
            }
        }
        .btn-expand-referral {
            position:absolute;
            left:0px;
            transition:all .2s;
            &:before {
                width:13px;
                content:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTQgOCIgZmlsbD0ibm9uZSI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjM5NzcgNy4zOTc3OEM3LjE3ODAyIDcuNjE3NDYgNi44MjE5MiA3LjYxNzQ2IDYuNjAyMjUgNy4zOTc3OEwwLjg2NzM4NyAxLjY2MjkxQzAuNjQ3NzIgMS40NDMyMyAwLjY0NzcyIDEuMDg3MTMgMC44NjczODcgMC44Njc0NTZMMS4xMzI1NiAwLjYwMjI1NkMxLjM1MjIyIDAuMzgyNTgxIDEuNzA4MzggMC4zODI1ODEgMS45MjgwNSAwLjYwMjI1Nkw2Ljk5OTk3IDUuNjc0MjFMMTIuMDcxOSAwLjYwMjI1NkMxMi4yOTE2IDAuMzgyNTgxIDEyLjY0NzcgMC4zODI1ODEgMTIuODY3NCAwLjYwMjI1NkwxMy4xMzI2IDAuODY3NDU2QzEzLjM1MjIgMS4wODcxMyAxMy4zNTIyIDEuNDQzMjMgMTMuMTMyNiAxLjY2MjkxTDcuMzk3NyA3LjM5Nzc4WiIgZmlsbD0iIzAwNDI1OSIvPgo8L3N2Zz4=");
            }
        }
        .card-list-title {
            display:flex;
            gap:10px;
            padding-left:40px;
            h6 {
                width:45%;
                margin:0px;
            }
            .subtitle {
                width:25%;
            }
        }
        .details {
            display:none;
            border-top:1px solid var(--Border-primary, #E8E8E8);
            background:#F7F7F7;
            margin: 0 -24px 0px;
            padding:20px 10px 10px;
            font-size:14px;
            transform:translateY(25px);
        }
    }


}