
.ConnectorClientInformationPage{
   
    .client-file-item {
        @extend .tile;
        padding:16px;
        flex-direction:row;
        justify-content:space-between;
        font-size:14px;
        
        .title {font-weight:500;}
        .size {color:#7E7E7E;}

        .icon {
            margin-right:16px;
            display: flex;
            width: 48px;
            height: 48px;
            padding: 12px;
            font-size: 20px;
            justify-content: center;
            align-items: center;
            gap: 12px;
            border-radius: 30px;
            border: 1px solid var(--Border-primary, #E8E8E8);
            background: var(--Background-color-primary, #F7F7F7);
        }
    }
}