.profile-step-progress {
    width:100%;
    height:20px;
    background:url(../../images/progress-bar-bg.png) repeat-x;
    background-size:calc(33% + 5px);
    background-position:left -5px center;
    border-radius:20px;
    margin-bottom:35px;
    // overflow: hidden;

    .profile-progress-bar {
        &:after {
            content:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBGcmVlIDYuNi4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlL2ZyZWUgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTI1NiA0OGEyMDggMjA4IDAgMSAxIDAgNDE2IDIwOCAyMDggMCAxIDEgMC00MTZ6bTAgNDY0QTI1NiAyNTYgMCAxIDAgMjU2IDBhMjU2IDI1NiAwIDEgMCAwIDUxMnpNMzY5IDIwOWM5LjQtOS40IDkuNC0yNC42IDAtMzMuOXMtMjQuNi05LjQtMzMuOSAwbC0xMTEgMTExLTQ3LTQ3Yy05LjQtOS40LTI0LjYtOS40LTMzLjkgMHMtOS40IDI0LjYgMCAzMy45bDY0IDY0YzkuNCA5LjQgMjQuNiA5LjQgMzMuOSAwTDM2OSAyMDl6Ii8+PC9zdmc+");
            display:block;
            width:38px;
            height:38px;
            background:$brand-blue-1;
            border-radius:50%;
            // border: 3px solid #fff;
            position:absolute;
            right: -19px;
            top: -9px;
        }
        &.one-third {
            position:relative;
            width:calc(33% - 2px);
            height:20px;
            background:$brand-blue-1;
            border-radius:20px;
        }
        &.one-quarter {
            position:relative;
            width:calc(25% - 2px);
            height:20px;
            background:$brand-blue-1;
            border-radius:20px;
        }
        &.one-half {
            position:relative;
            width:calc(50% - 2px);
            height:20px;
            background:$brand-blue-1;
            border-radius:20px;
        }
        &.two-thirds {
            position:relative;
            width:calc(66% - 2px);
            height:20px;
            background:$brand-blue-1;
            border-radius:20px;
        }
        &.three-quarters {
            position:relative;
            width:calc(75% - 2px);
            height:20px;
            background:$brand-blue-1;
            border-radius:20px;
        }
        &.full, &.complete {
            position:relative;
            width:100%;
            height:20px;
            background:$brand-blue-1;
            border-radius:20px;
            &:after {
                right:0px;
            }
        }
    }
}

.profile-step {
    display: flex;
    align-items: center;
    justify-content:space-between;
    margin:0 -30px;
    padding:25px 30px;
    border-top: 1px solid var(--Light-Gray-2, #EDEDED);
    text-decoration:none;

    &:last-child {
        padding-bottom:1px;
    }

    .icon-status {
        font-size:30px;
        color:#CCCCCC;
    }

    &.complete {
        .icon-status {
            color:$brand-blue-1;
        }
    }

    .strike {
        text-decoration: line-through;
        color: #CCCCCC;
        font-weight:600;
    }

    .main {
        display: flex;
        align-items: center;
        .icon {
            font-size:30px;
            padding-right:20px;
            color:$brand-dark;
        }
    }
}

.referral-detail-modal {

    .info-row {
        display:flex;
        align-items: center;
        padding-bottom:10px;
        gap:10px;
    }
    .icon {
        width:20px;
    }
    .hours-row {
        display:flex;
        width:200px;
        justify-content:space-between;
        font-size:14px;
        color:#7e7e7e;
    }
}