// Buttons
.btn, input[type=submit] {
    align-self: center;
    color:#fff;
    // padding: 11px;

    &:hover {
        color:#fff;
    }

    &.btn-secondary {
        color:$brand-dark;
    }
    &:focus {
        color:#fff !important;
    }
    &:active, &.active {
        color:#fff !important;
    }
    &.disabled {
        color:#fff !important;
    }
}

.btn-primary {
    &:hover {
        color:#fff !important;
    }
}

.btn-secondary:hover {
    color:$brand-dark;
    background:#d0d0d0;
}

.btn-outline-warning {
    color:$orange;
}

.btn-outline-danger {
    color:$red;
}
.btn-outline-primary {
    color:$brand-blue-1;
}

.btn.wide {
    padding-left:50px;
    padding-right:50px;
}

.btn-wide {
    width:230px;
}

.btn.large {
    @extend .btn-lg;
}

.btn-signup {
    width:400px;
    padding: 11px;
}

.btn-full {
    width:100%;
}

.btn-icon {
    display:flex;
    align-items:center;
    justify-content:center;
    i {
        font-size: 20px;
        margin-right:8px;
    }
}

.btn-circle {
    display:flex;
    align-items:center;
    justify-content:center;
    padding:0px;
    width:42px;
    height:42px;
}

.btn-link {
    color:$brand-dark;
    text-decoration: none;
    &:hover {
        color:$brand-dark;
        text-decoration: underline;
    }
}

.link-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
    color:$brand-blue-1;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    &.dark{
        color:$brand-dark;
    }
}

.link-text-arrow {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
    color:$brand-blue-1;
    &:hover {
        text-decoration: underline
    }
    &:after {
        width: 27px;
        position: absolute;
        padding-left: 8px;
        padding-top: 2px;
        content:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tIUZvbnQgQXdlc29tZSBQcm8gNi43LjEgYnkgQGZvbnRhd2Vzb21lIC0gaHR0cHM6Ly9mb250YXdlc29tZS5jb20gTGljZW5zZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tL2xpY2Vuc2UgKENvbW1lcmNpYWwgTGljZW5zZSkgQ29weXJpZ2h0IDIwMjQgRm9udGljb25zLCBJbmMuLS0+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTUwNy4zIDI2Ny4zYzYuMi02LjIgNi4yLTE2LjQgMC0yMi42bC0xNDQtMTQ0Yy02LjItNi4yLTE2LjQtNi4yLTIyLjYgMHMtNi4yIDE2LjQgMCAyMi42TDQ1Ny40IDI0MCAxNiAyNDBjLTguOCAwLTE2IDcuMi0xNiAxNnM3LjIgMTYgMTYgMTZsNDQxLjQgMEwzNDAuNyAzODguN2MtNi4yIDYuMi02LjIgMTYuNCAwIDIyLjZzMTYuNCA2LjIgMjIuNiAwbDE0NC0xNDR6Ii8+PC9zdmc+");
    }
}

.link-text-secondary {
    color: var(--Text-secondary, #7E7E7E);
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

// .link-text-tertiary {
//     color: var(--Text-secondary, #7E7E7E);
//     font-size: 14px;
//     font-weight: 400;
//     text-decoration: none;
//     &:hover {
//         text-decoration: underline;
//     }
// }

.btn-close {
    position: relative;
    font-size: 14px;
    color:$brand-dark;
    padding: 5px;
    opacity: 1;
    background:transparent;

    &:before {
        content:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
    }

    &:after {
        content: "";
        position: absolute;
        border-radius: 50%;
        top: -7px;
        left: -7px;
        background: #e8e8e8;
        width: 38px;
        height: 38px;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.3s;
    }
    &:hover:after {
        opacity:1;
    }
}

.share-buttons {
    padding-bottom:20px;
}

.btn-share {
    text-decoration:none;
}
