.modal {
    .header-icon-wrap {
        display:flex;
        align-items:center;
        justify-content:center;
        font-size:30px;
        background: #FEF3F2;
        width:88px;
        height:88px;
        margin:0 auto;
        border-radius:50%;
        margin-bottom:15px;

        &.blue {
            background: #D5EFF7;
        }

    }

    .icon-double-layer {
        position: relative;
        background:#46badb36;
        
        i:first-child {
            color:#fff;
        }
        i:last-child {
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
            color:$brand-dark;
        }
    }
    
}

.modal-header {
    border:none;
    padding-bottom:0px;
}

// Profile Modal
.profile-modal {
    .modal-dialog {
        max-width:660px;
    }
    .user-photo {
        width:90px;
    }

    .profile-info-line {
        display:flex;
        align-items:center;
        .icon {
            width:30px;
            font-size:24px;
            margin-right:10px;
            text-align:center;
            &.light {
                color:var(--Light-Blue-1, #46BADB);
            }
        }
    }
    .needs-priorities {
        .btn {
            display:inline-flex;
            align-items:center;
            justify-content:space-between;
            margin:4px 0;
        }
        i {margin-right:7px;}
    }
}

// Event Modal
#event-modal {
    .event-row {
        display:flex;
        align-items:flex-start;
        padding-bottom:15px;

        .event-row-icon {
            flex-shrink: 0;
            width:40px;
            padding-right:12px;
            font-size:20px;
        }
        p {
            margin:0px;
        }
    }

    .user-photo {
        margin-right:12px;
    }

    .delete-event-icon{
        margin-bottom: 15px
    }

    #DeleteEventForm_action_delete{
        background-color: transparent !important;
        border: solid 1px $red !important;
        color: $red !important;
        cursor: pointer;
    }
    
    .btn-cancel{
        color: var(--Text-secondary, #7E7E7E);
        margin-left: 15px;
        cursor: pointer;
    }

}

#schedule-now-modal{
    .row{
        padding: 10px 0; 

        &.bb{
            border-bottom: 1px solid #E8E8E8;
        }
    }
}

.modal-toolbar {
    display:flex;
    width:100%;
    align-items:center;
    justify-content:flex-end;

    a {
        color: #004259;
        text-decoration: none;
        transition:.3s all;
        display:inline-flex;
        align-items:center;
        justify-content:center;
        width:35px;
        height:35px;
        border-radius: 50%;
        font-size:20px;
        margin-left:5px;
        &:hover {
            background:#F7F7F7;
        }
    }
}

.unclaim-modal {
    .header-icon-wrap {
        color:$orange;
    }
}
.archive-modal {
    .header-icon-wrap {
        color:$red;
    }
}