.table-header {
    @extend .module-container;
    margin-bottom:0px !important;
    border-radius:10px 10px 0 0 !important;
    border-bottom:none !important;

    .toolbar-wrap {
        display:flex;
        justify-content:space-between;
        .search {
            position: relative;
            width:calc(60% - 20px);
            &:before {
                position: absolute;
                left:15px;
                top:16px;
                content:url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjUgNi41QzExLjUgNC43MTg3NSAxMC41MzEyIDMuMDkzNzUgOSAyLjE4NzVDNy40Mzc1IDEuMjgxMjUgNS41MzEyNSAxLjI4MTI1IDQgMi4xODc1QzIuNDM3NSAzLjA5Mzc1IDEuNSA0LjcxODc1IDEuNSA2LjVDMS41IDguMzEyNSAyLjQzNzUgOS45Mzc1IDQgMTAuODQzOEM1LjUzMTI1IDExLjc1IDcuNDM3NSAxMS43NSA5IDEwLjg0MzhDMTAuNTMxMiA5LjkzNzUgMTEuNSA4LjMxMjUgMTEuNSA2LjVaTTEwLjUzMTIgMTEuNjI1QzkuNDA2MjUgMTIuNSA4IDEzIDYuNSAxM0MyLjkwNjI1IDEzIDAgMTAuMDkzOCAwIDYuNUMwIDIuOTM3NSAyLjkwNjI1IDAgNi41IDBDMTAuMDYyNSAwIDEzIDIuOTM3NSAxMyA2LjVDMTMgOC4wMzEyNSAxMi40Njg4IDkuNDM3NSAxMS41OTM4IDEwLjU2MjVMMTUuNzgxMiAxNC43MTg4QzE2LjA2MjUgMTUuMDMxMiAxNi4wNjI1IDE1LjUgMTUuNzgxMiAxNS43ODEyQzE1LjQ2ODggMTYuMDkzOCAxNSAxNi4wOTM4IDE0LjcxODggMTUuNzgxMkwxMC41MzEyIDExLjYyNVoiIGZpbGw9IiM3RTdFN0UiLz4KPC9zdmc+");
            }
            input {
                padding-left:40px;
            }
        }
        .filter {
            width:40%;
        }    
    }
}
.data-table {
    width:100%;
    // border: 1px solid var(--Border-primary, #E8E8E8);
    border-radius:0 0 10px 10px;
    background:#fff;
    font-weight:500;
    outline: 1px solid #E8E8E8;
    outline-offset: -1px;

    tr {
        border-bottom: 1px solid var(--Border-primary, #E8E8E8);
    }
    th {
        padding:16px 24px;
        font-weight:500;
    }
    td {
        position: relative;
        padding:28px 24px;
    }

    tbody {
        tr:last-child {
            border-radius:0 0 10px 10px;
        }
        tr:hover {
            background: var(--Background-color-primary, #F7F7F7);
        }

    }

    .client-row.last, .client-row:last-child { 
        border-radius:0 0 10px 10px;
        border-bottom:none;
    }

    .row-empty:hover {
        background:transparent;
    }

}

tbody {
    tr {
        font-weight:400;
    }
}

th[data-sortby] {
    cursor:pointer;
    position:relative;
}
th[data-sort="ASC"]:after {
    content: '\2191';
    position: relative;
    left:10px;
}
th[data-sort="DESC"]:after {
    content: '\2193';
    position: relative;
    left:10px;
}

