.ClientAccountPage {

    .tab-nav {
        margin:20px 0;
        a {
            color: var(--Text-body, rgba(0, 66, 89, 0.70));
            text-decoration:none;
            padding-right:25px;
        }

        .active {
            color:$brand-dark;
            font-size: 16px;
            font-weight: 500;
            text-decoration:underline;
        }
    }

    .client-file-item {
        @extend .tile;
        padding:16px;
        flex-direction:row;
        justify-content:space-between;
        font-size:14px;
        
        .title {font-weight:500;}
        .size {color:#7E7E7E;}

        .icon {
            margin-right:16px;
            display: flex;
            width: 48px;
            height: 48px;
            padding: 12px;
            font-size: 20px;
            justify-content: center;
            align-items: center;
            gap: 12px;
            border-radius: 30px;
            border: 1px solid var(--Border-primary, #E8E8E8);
            background: var(--Background-color-primary, #F7F7F7);
        }
    }

}

#needs-list-sortable, #client-needs-list-sortable {
    .needs-list-item, .client-needs-list-item {
        display: flex;
        align-items: center;
        cursor:move;
        border-radius: 5px;
        background: var(--Color-Neutral-neutral-lightest, #EEE);
        height:52px;
        padding: 0 15px;
        font-size:14px;
        font-weight:500;
        margin-bottom:10px;
    
        i {
            opacity:.6;
            font-size:16px;
            margin-right:15px;
        }
    }

    .sortable-ghost {
        background:rgba(0,0,0,.7);
        color:rgba(255,255,255,.85);
    }
    
}

.need-item {
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: var(--Color-Neutral-neutral-lightest, #EEE);
    height:52px;
    padding: 0 15px;
    font-size:14px;
    font-weight:500;
    margin-bottom:10px;

    i {
        opacity:.6;
        font-size:16px;
        margin-right:15px;
    }
}

.priority-number {
    display:flex;
    height:52px;
    margin-bottom:10px;
    justify-content:center;
    align-items:center;
}
