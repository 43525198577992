// Footer
footer {
    margin-top: 24px;

    .top-row {
        border-top:1px solid #E8E8E8;
        padding-top:42px;
        .link {
            display:block;
            color: var(--Text-primary, #004259);
            text-align: left;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            text-decoration:none;
            padding-top:5px;
            padding-bottom:5px;
            padding-right:24px;
            &:hover {
                text-decoration: underline;
            }

            @include media-breakpoint-up(md) {
                display:inline-block;
                text-align: center;
                padding-top:0px;
                padding-bottom:0px;
            }
        }
    
        .col-right {
            .link {
                @include media-breakpoint-up(md) {
                    padding-right:0px;
                    padding-left:24px;
                }
            }
        }
    }

    .bottom-row {
        padding-top:42px;
        padding-bottom:24px;
        color: var(--Color-Neutral-neutral, #7E7E7E);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        opacity:.3;

        a {
            padding:0 10px;
        }
    }
}