.ClientPlansPage {

    .top-alert-banner {
        background-size:contain;
        @include media-breakpoint-up(lg) {
            background:#fff url('../../images/plan-banner-bg.png') no-repeat right -70px top;
        }
        @include media-breakpoint-up(xl) {
            background:#fff url('../../images/plan-banner-bg.png') no-repeat right top;
        }

        .btn-close:before {
            @include media-breakpoint-up(lg) {
                filter: invert(1);
            }
        }
        .btn-close:hover:after {
            @include media-breakpoint-up(lg) {
                opacity:.2;
            }
        }

        h2 {
            @include media-breakpoint-up(lg) {
                font-size:40px;
                padding-bottom:20px;
            }
        }
    }
}