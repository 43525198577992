@import "bootstrap";


@import "./partials/nav.scss";
@import "./partials/brand.scss";
@import "./partials/buttons.scss";
@import "./partials/modals.scss";
@import "./partials/footer.scss";
@import "./partials/notifications.scss";
@import "./partials/client.scss";
@import "./partials/forms.scss";
@import "./partials/tables.scss";

@import "./components/sidebar.scss";
@import "./components/list-menu.scss";
@import "./components/plans.scss";
@import "./components/checklist.scss";
@import "./components/accordion.scss";
@import "./components/progress.scss";

@import "./pages/createplan.scss";
@import "./pages/resourcesblog.scss";
@import "./pages/clientaccount.scss";
@import "./pages/clientplans.scss";
@import "./pages/clientsignup.scss";
@import "./pages/messages.scss";
@import "./pages/calendarpage.scss";
@import "./pages/clientinfo.scss";


// Global Utilities (TODO - Move these)---------------------
@mixin bg-opacity($color, $amount: .2) {
    background: rgba($color, $amount);
}

.hide {
    display:none !important;
}


.grid-2-col {
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:20px;
}
.grid-2-col-lg {
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:20px;
    @media (max-width: 992px) {
        grid-template-columns: 1fr;
    }
}

.grid-3-col {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:20px;
}
.grid-3-col-lg {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:20px;
    @media (max-width: 992px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}
// End Global Utilities

body {
    // padding-top:20px;
    background:#F7F7F7;

    @include media-breakpoint-up(md) {
        padding-top:20px;
        padding-left:100px;
    }
}




@font-face {
    font-family: 'FeltThat';
    src: url('../FeltThat.otf') format('truetype');
    font-display: swap;
}
.FeltThat {
    font-family: 'FeltThat';
    font-size: 48px;
}