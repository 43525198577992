.IntakePage{
    padding: 0;

    .logo{
        text-align: center;
        padding-bottom: 45px;
        .logo-image{
            position: relative;
            max-width: 425px;
        }
    }


    .footer{
        margin: 25px 0 100px 0;
    }
    
    .footer-logo-image{
        max-width: 100px;
        margin-bottom: 25px;
    }

    section.heading {
		position: relative;
		padding: 75px 0;
		margin-bottom: 70px;
		color: #fff;
		text-align: center;
		font-size: 24px;

		&::before {
			position: absolute;
			background: linear-gradient(to bottom, #004259, #2391AF);
			width: 100%;
			left: 0;
			top: 0;
			content: "";
            height: 100%;
		}

		.title {
			margin-bottom: 26px;
			color: #fff;
            position: relative;
            font-weight: 400;
            font-size: 56px;
		}

		.subtitle {
			color: #fff;
			margin-bottom: 26px;
            position: relative;
            font-weight: 400;
            font-size: 18px;
            max-width: 850px;
            margin: 0 auto;
		}
	}

    .form-container{
        max-width: 850px;
        margin: 0 auto;
    }

    .image-radio,.ss-image-radio {
        cursor: pointer;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border: 2px solid #EDEDED;
        margin-top: 12px;
        margin-bottom: 12px;
        outline: 0;
        border-radius:10px; 
        padding:31px 0 41px 0;
        width: 146px;
        background-color: $white;

        .circle { 
            width:58px; 
            height:58px; 
            border-radius:50%;  
            padding: 15px; 
            background-color:rgba(70, 182, 219, 0.2); 
            margin: 0 auto; 
        }

        .radio-text{ font-size: 16px; margin-top: 18px; }
    }

    .image-radio-checked { 
        border-color: #46badb; 
        .circle{ background-color:rgba(70, 182, 219, 0.5); }
    }
    
    .icon-double-layer{ 
        font-size: 24px; 
    }

    .form-subtitle{
        max-width: 700px;
        margin: 10px auto;
    }

    .need-btn, .hc-need-btn{
        margin:6px;
    }

    .btn-toolbar{
       justify-content: center;
        margin-top: 50px;
        margin-bottom: 80px;
    }

    #hark-form_action_doHarkSubmit,
    #hark-form_action_doTwoOneOneSubmit,
    #hark-form_action_doUAMSSubmit{
        width: 100%;
        max-width: 300px;
    }

    #intake-request-needs{
        margin: 0 auto;
    }

    &.UAMS{
        section.heading {
            &::before {
                background: linear-gradient(to bottom, #A20D32, #D41E4B);
            }
        }

        .image-radio,.ss-image-radio {
            .circle { 
                background-color:rgba(254, 208, 219, 0.2); 
            }
        }
    
        .image-radio-checked { 
            border-color: #B82549; 
            .circle{ background-color:rgba(254, 208, 219, 0.5); }
        }
        .need-btn, .hc-need-btn{
            &.btn-primary {
                background-color: #A20D32;
                border-color: #A20D32;
                color: #fff;
            }
        }
        #hark-form_action_doUAMSSubmit{
            background-color: #A20D32;
            border-color: #A20D32;
            color: #fff;
        }
    }
    
}